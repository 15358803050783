import {Link} from "react-router-dom";

const AppNavigator = () => {
    return (
        <div className="row app-navigator d-none d-sm-block">
            <div className="col-12 layout-padding ">
                <ul className="nav-list">
                    <li className="nav-item">
                        <Link to={'/'} className="nav-link">Trang chủ</Link>
                    </li>
                    {/*<li className="nav-item">*/}
                    {/*    <a className="nav-item-link">Vòng quay may mắn</a>*/}
                    {/*</li>*/}
                    {/*<li className="nav-item">*/}
                    {/*    <Link to={'/dau-gia'} className="nav-link">Đấu giá</Link>*/}
                    {/*</li>*/}
                    <li className="nav-item">
                        <Link to={'/nap-tien'} className="nav-link">Nạp tiền</Link>
                    </li>
                    <li className="nav-item">
                        <Link to={'/san-pham'} className="nav-link">Sản phẩm</Link>
                    </li>
                    <li className="nav-item">
                        <Link to={'/dich-vu'} className={'nav-link'}>Dịch vụ</Link>
                    </li>
                    <li className="nav-item">
                        <Link to={'/dieu-khoan'} className={'nav-link'}>Điều khoản</Link>
                    </li>
                    <li className="nav-item">
                        <Link to={'/bao-mat'} className="nav-link">Bảo mật</Link>
                    </li>
                    <li className="nav-item">
                        <Link to={'/cong-tac-vien'} className="nav-link">Cộng tác viên</Link>
                    </li>
                    <li className="nav-item">
                        <Link to={'/lich-su-mua'} className="nav-link">Lịch sử</Link>
                    </li>
                </ul>
            </div>
        </div>
    );
}
export default AppNavigator; 