import {Link, useNavigate} from "react-router-dom";
import icon from "../../assets/image/user.svg";
import group_1 from "../../assets/image/166879375_2362580400545015_7930383412177569546_n.jpg";
import LoginSection from "./LoginSection";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons/faBars";
import {Offcanvas} from "react-bootstrap";
import {useState} from "react";


const AppLogo = () => {
    const navigate = useNavigate();
    const [showMenuCanvas, setShowMenuCanvas] = useState(false);

    const handleClose = () => setShowMenuCanvas(false);
    const handleShow = () => setShowMenuCanvas(true);

    return (
        <div className="row logo-menu">
            <div className="col-12 d-flex justify-content-between align-items-center layout-padding">
                <div className="app-logo d-flex align-items-center cursor-pointer" onClick={() => navigate('/')}>
                    <div className="logo me-2">
                        <img className={'rounded-circle'} src={group_1} alt={'trang-chu'}/>
                    </div>
                    <div className="text-uppercase app-title">
                        <h5 className="mb-1">ACCFIFA.VN</h5>
                        <h6 className="m-0">uy tín - chất lượng - nhanh gọn</h6>
                    </div>
                </div>
                <div className="d-flex justify-content-end align-items-center">
                    <LoginSection/>
                    <div className="d-block d-sm-none">
                        <button className="btn btn-link btn-menu-mobile" onClick={handleShow}>
                            <FontAwesomeIcon icon={faBars}/>
                        </button>
                        <Offcanvas show={showMenuCanvas} onHide={handleClose} responsive="md">
                            <Offcanvas.Header closeButton className="menu-mobile-header" closeVariant="white">
                                <div className="app-title">
                                    <h5 className="mb-1">ACCFIFA.VN</h5>
                                    <h6 className="m-0">uy tín - chất lượng - nhanh gọn</h6>
                                </div>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <ul className="menu-mobile-content">
                                    <li className="nav-item">
                                        <Link to={'/'} className="nav-item-link" onClick={handleClose}>Trang chủ</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={'/nap-tien'} className="nav-item-link" onClick={handleClose}>Nạp tiền</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={'/san-pham'} className="nav-item-link" onClick={handleClose}>Sản phẩm</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={'/dich-vu'} className={'nav-item-link'} onClick={handleClose}>Dịch vụ</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={'/dieu-khoan'} className={'nav-item-link'} onClick={handleClose}>Điều khoản</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={'/bao-mat'} className="nav-item-link" onClick={handleClose}>Bảo mật</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={'/cong-tac-vien'} className="nav-item-link" onClick={handleClose}>Cộng tác viên</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={'/lich-su-mua'} className="nav-item-link" onClick={handleClose}>Lịch sử</Link>
                                    </li>
                                    {/*<li className="nav-item">*/}
                                    {/*    <Link to={'/dau-gia'} className="nav-item-link" onClick={handleClose}>Đấu giá</Link>*/}
                                    {/*</li>*/}
                                    {/*<li className="nav-item">*/}
                                    {/*    <a className="nav-item-link">Vòng quay may mắn</a>*/}
                                    {/*</li>*/}
                                </ul>
                            </Offcanvas.Body>
                        </Offcanvas>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default AppLogo;